import axios from 'axios'
import history from '../history'
import {AUTH_TOKEN} from 'redux/constants/Auth'
import {notification} from 'antd';
import {API_BASE_PATH} from "../configs/AppConfig";

const service = axios.create({
	baseURL: API_BASE_PATH,
	timeout: 60000,
	headers: {
		'X-Requested-With': 'XMLHttpRequest'
	}
})

// Config
const ENTRY_ROUTE = '/auth/login'
const TOKEN_PAYLOAD_KEY = 'authorization'
const PUBLIC_REQUEST_KEY = 'public-request'

// API Request interceptor
service.interceptors.request.use(config => {

	config.headers['X-Requested-With'] = 'XMLHttpRequest';

	const jwtToken = localStorage.getItem(AUTH_TOKEN)
	
  if (jwtToken) {
    config.headers[TOKEN_PAYLOAD_KEY] = `Bearer ${jwtToken}`
  }

  if (!jwtToken && !config.headers[PUBLIC_REQUEST_KEY]) {
		history.push(ENTRY_ROUTE)
		window.location.reload();
  }

  return config
}, error => {
	// Do something with request error here
	notification.error({
		message: 'Error'
	})
  Promise.reject(error)
})

// API respone interceptor
service.interceptors.response.use( (response) => {
	return response
}, (error) => {

	let notificationParam = {
		message: ''
	}
	
	// Remove token and redirect 
	if (error.response.status === 400 || error.response.status === 401) {
		notificationParam.message = 'Authentication Fail'
		notificationParam.description = 'Please login again'
		localStorage.removeItem(AUTH_TOKEN)
		history.push(ENTRY_ROUTE)
		window.location.reload();
	}
	//
	// if (error.response.status === 404) {
	// 	notificationParam.message = 'Not Found'
	// }
	//
	// if (error.response.status === 500) {
	// 	notificationParam.message = 'Internal Server Error'
	// }
	//
	// if (error.response.status === 508) {
	// 	notificationParam.message = 'Time Out'
	// }
	//
	// if (error.response.status === 422) {
	// 	notificationParam.message = "Validation Error"
	// 	notificationParam.description = 'Please resolve all errors.'
	// }
	//
	// notification.error(notificationParam)

	return Promise.reject(error);
});

export const setAuthorizationToken = (token) => {
	service.interceptors.request.use(
			async config => {
				config.headers = {
					'authorization': `Bearer ${token}`,
				}
				return config;
			});
};

export default service